import { bindable } from 'aurelia-framework';
import { Calendar } from 'services/calendar';
import { MemberConfig } from 'services/member-config';
import { Notifier } from 'common/ui';
import { defineCustomElement } from '@nylas/web-elements/dist/cdn/nylas-scheduling/nylas-scheduling.es';

export class MyBookingCalendarButton {
    static inject = [Calendar, MemberConfig, Notifier];
    _calendar;
    _memberConfig;
    _notifier;

    @bindable bookName;
    @bindable bookEmail;
    @bindable bookPhone;
    @bindable bookAddress;
    @bindable bookDob;
    @bindable buttonClass = 'btn btn-icon btn-outline me-3';
    @bindable buttonText;
    @bindable iconClass = 'fs-3 fa-duotone fa-calendar-days';
    @bindable settingKey;
    @bindable meSize = '3';

	constructor(calendar, memberConfig, notifier) {
        this._calendar = calendar;
        this._memberConfig = memberConfig;
        this._notifier = notifier;
	}

    attached() {
        this._initializeNylas();
    }

    _initializeNylas() {
        try {
            defineCustomElement();
        } catch (err) {
            console.log(err);
        }
    }

    async openBookingCalendar() {
        try {
            if (!this._bookingCalendarConfigurationId && this.settingKey) this._bookingCalendarConfigurationId = await this._memberConfig.value(this.settingKey);
            if (!this._bookingCalendarConfigurationId) {
                this._notifier.info('lead-scheduler-not-set-up', undefined, true);
                return;
            }

            this.nylasSchedulingContainerEl.innerHTML = '';
            const nylasSchedulingEl = document.createElement('nylas-scheduling');
            nylasSchedulingEl.schedulerApiUrl = 'https://api.us.nylas.com';
            nylasSchedulingEl.configurationId = this._bookingCalendarConfigurationId;
            this.nylasSchedulingContainerEl.appendChild(nylasSchedulingEl);
            this.showLightbox = true;
            this.lightboxVM.open(true, false);
        } catch (err) {
            console.log(err);
            this.lightboxClosed();
        }
    }

    lightboxClosed() {
        this.showLightbox = false;
    }
}
